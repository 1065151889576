<template xmlns="http://www.w3.org/1999/html">
  <div class="az-content az-content-profile">
    <div class="container mn-ht-100p">
      <div class="az-content-left az-content-left-profile">
        <div class="az-profile-overview">
          <div class="az-img-user">
            <div id="profile-upload" :style="user_image_background">
              <div class="hvr-profile-img">
                <input type="file" name="logo" class="upload w180" title="Click to upload image" id="getval" @change="fileChange">
              </div>
              <i class="fa fa-2x fa-camera"></i>
            </div>
          </div>
          <div class="d-flex justify-content-between mg-b-20">
            <div>
              <h5 class="az-profile-name" v-if="currentUser">{{currentUser.name}}</h5>
              <p class="az-profile-name-text">{{currentUser.email}}</p>
            </div>
          </div>
          <div class="az-profile-bio">

          </div>
          <hr class="mg-y-30">
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>

          <!-- <div class="az-content-label tx-13 mg-b-25">Contact Information</div>
          <div class="az-profile-contact-list">
            <div class="media">
              <div class="media-icon"><i class="icon ion-md-locate"></i></div>
              <div class="media-body">
                <span>Current Address</span>
                <div></div>
                <div></div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="az-content-body az-content-body-profile">
        <nav class="nav az-nav-line">
          <a href="#account" class="nav-link" :class="{active: activeTab === 0}" data-toggle="tab" @click="overviewClick">Overview</a>
          <a href="#wallet" class="nav-link" :class="{active: activeTab === 1}" data-toggle="tab" @click="walletClick">Wallet</a>
        </nav>
        <div class="az-tab-content">
          <div class="az-profile-body" :class="{'d-none': activeTab !== 0}" id="account">

            <div class="row mg-b-20">
              <div class="col-md-12 col-xl-12">
                <div class="az-profile-view-chart">
                  <div class="az-profile-view-info col-md-12">
                    <!-- <div class="d-flex align-items-baseline"> -->
                    <div class="row row-sm form-group">
                      <div class="col-lg-4 ">
                        <h6>Account Settings</h6>
                      </div>
                      <div class="col-lg-4 ">
                        <button @click="edit" class="btn btn-orange rounded w-75 font-weight-bold"><i class="fas fa-pen"></i>&nbsp;&nbsp;Edit Settings
                        </button>
                      </div>
                      <div class="col-lg-4 ">
                        <button @click="changePassword" class="btn btn-orange rounded w-75 font-weight-bold"><i class="fas fa-pen"></i>&nbsp;&nbsp;Change&nbsp;Password
                        </button>
                      </div>
                    </div>
                    <!-- </div> -->
                    <p>Please keep your profile information always current in order to help you when you contact us.</p>
                    <div class="az-contact-info-body ps" style="margin-left: -36px">
                      <div class="media-list">
                        <div class="media">
                          <div class="media-icon">
                            <i class="fas fa-user" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>First Name</label>
                              <span class="tx-medium">{{accountDetails.first_name}}</span>
                            </div>
                          </div>
                          <div class="media-icon">
                            <i class="far fa-user" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>Last Name</label>
                              <span class="tx-medium">{{accountDetails.last_name}}</span>
                            </div>
                          </div><!-- media-body -->
                        </div><!-- media -->

                        <div class="media">
                          <div class="media-icon">
                            <i class="far fa-envelope" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>Email</label>
                              <span class="tx-medium">{{accountDetails.email}}</span>
                            </div>
                          </div>

                          <div class="media-icon">
                            <i class="fa fa-certificate" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>Email Verified ?</label>
                              <span class="tx-medium">{{accountDetails.email_verified}}</span>
                            </div>
                          </div><!-- media-body -->
                        </div>

                        <div class="media">
                          <div class="media-icon">
                            <i class="fa fa-door-open" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>Email 2-Factor</label>
                              <span class="tx-medium">{{accountDetails.email2f_enabled}}</span>
                            </div>
                          </div>
                          <div class="media-icon">
                            <i class="fa fa-door-open" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>TOTP</label>
                              <span class="tx-medium">{{accountDetails.totp_enabled}}</span>
                            </div>
                          </div><!-- media-body -->
                        </div>

                        <div class="media">
                          <div class="media-icon">
                            <i class="fa fa-door-open" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>HOTP</label>
                              <span class="tx-medium">{{accountDetails.hotp_enabled}}</span>
                            </div>
                          </div>
                          <div class="media-icon">
                            <i class="fa fa-door-open" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>U 2-Factor</label>
                              <span class="tx-medium">{{accountDetails.u2f_enabled}}</span>
                            </div>
                          </div><!-- media-body -->
                        </div><!-- media -->
                      </div><!-- media-list -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="{'d-none': activeTab !== 1}" class="az-profile-body" id="wallet">
            <div class="card-body">
              <div class="d-flex align-items-baseline">
                <h2>Wallet</h2>
              </div>
              <p>Please keep your keys secure</p>
              <h3 v-if="balance" class="mg-t-30">{{balance}}<span>GRAM</span></h3>
              <label>Account Balance</label>
              <div class="row mg-t-15">
                <div v-if="accountDetails.user_wallets.length === 0">
                  <h5 class="text-muted">No Wallets Available</h5>
                </div>
                <div v-else>
                  <div v-for="(a, index) in accountDetails.user_wallets" class="col-md-6 mg-t-15 d-flex no-block align-items-center">
                    <div>
                      <blockie :address="a.public_key"></blockie>
                    </div>
                    <a class="mg-l-5" href="javascript:void(0);" v-clipboard:copy="a.public_key" v-clipboard:success="copied">
                      {{a.public_key | truncate(35, '....')}}
                    </a>
                  </div>
                  <div class="col-md-4 mg-t-auto">
                    <button :disabled="isRequested" @click="sendMailRequest(accountDetails.name, accountDetails.email)" class="btn btn-sm btn-az-primary btn-block rounded">
                      <span v-if="!isRequested">Request Funds</span>
                      <span v-if="isRequested"><i class="fa fa-spinner fa-spin"></i> &nbsp;Loading</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="mg-b-20"></div>

          </div><!-- az-profile-body -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/event-bus";

export default {
  name: "UserAccount",
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      isRequested: false,
      interval: null,
      activeTab: 0,
      accountDetails: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        email2f_enabled: null,
        totp_enabled: null,
        hotp_enabled: null,
        u2f_enabled: null,
        email2f: null,
        hotp: null,
        totp: null,
        u2f: null,
        hotp_counter: null,
        email_verified: null,
        failed_login_count: null,
        last_password_change: null,
        user_wallets: []
      },
      balance: null,
      token_amount: null,
      image: null,
      src: null,
      user_image_background: localStorage.getItem("user_image") ? 'background-image:url(\'' + localStorage.getItem("user_image") + '\')' : ''
    };
  },
  mounted() {
    this.getUser();

  },
  methods: {
    async getUser() {
      try {
        let reply = await this.$accounts.get(`/accounts/user`);
        this.accountDetails = reply.data;
      } catch (e) {

      }
    },
    overviewClick() {
      this.activeTab = 0;
    },
    walletClick() {
      this.activeTab = 1;
    },
    edit() {
      this.$router.push('/myaccount/edit');
    },
    changePassword() {
      this.$router.push('/myaccount/changepassword');
    },
    async sendMailRequest(_name, _email) {
      try {
        this.isRequested = true;
        let request_body = {
          subject: 'Request for Token',
          body: `<p>Hi Qlikchain, <br><br>${_name} has email address <span style="color: #2a59a6">${_email}</span>, requests for tokens to this wallet which has public key <span style="font-weight: bold;font-style: italic;">${this.accountDetails.user_wallets[0].public_key}</span></p>`,
          email_address: process.env.VUE_APP_TOKEN_REQUEST_RECIPIENT_EMAIL
        };
        await this.$accounts.post(`/accounts/email`, request_body);
        this.$toastr.s('Request for the token has been successfully done', 'Success');
      } catch (e) {
        console.error(e);
        this.$toastr.e('Request failed', 'Error!');
      } finally {
        this.isRequested = false;
      }
    },
    copied() {
      this.$toastr.i("Copied to clipboard", "Copied");
    },
    fileChange(e) {
      this.image = e.target.files[0];
      this.upload();
    },
    async upload() {
      try {
        EventBus.$emit('openLoader');
        let f = new FormData();
        f.append('file', this.image);
        let user = await this.$accounts.patch(`/accounts/user/picture`, f, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'BWS ' + localStorage.getItem("token")
          }
        });
        let image = user.data.image.replace('https://profile-console.s3.ap-south-1.amazonaws.com/', 'https://d10yw3sjtpttkq.cloudfront.net/');
        localStorage.setItem("user_image", image);
        this.user_image_background = image ? 'background-image:url(\'' + image + '\')' : '';

      } catch (e) {
        console.log(e);
        this.$toastr.e("Failed to upload new image", 'Error');
      } finally {
        EventBus.$emit('closeLoader');

      }
    },

  }
}
</script>

<style scoped>
</style>
